/* You can add global styles to this file, and also import other style files */
.underline_link{
    color: #0F8BFD !important;
    text-decoration: underline;
}

.p-calendar {
    position: relative;
    display: inline-flex;
    width: 100% !important;
}
body {
  background: linear-gradient(180deg, #2A3957 0%, #232433 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; 
}

/* div {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
} */
.p-dropdown
 {
    width: 100% !important;
    height: 35px;
    padding: 0px;
}

.p-dropdown .p-dropdown-trigger
{
    background-color: #0F8BFD !important;
}

.header_title
{
    font-size: 25px;
    margin-left: 10px;
    font-weight: bold;
}

.header_subtitle{
  margin-top: 20px;
  font-size: 15px;
  font-weight: bold;
  max-width: 700px;
  width: 90%;
  text-align: center;
}

.content_text{
  font-size: 15px;
  max-width: 700px;
  text-align: justify;
}

.footer_subtitle{
  margin-top: 20px;
  font-size: 15px;
  max-width: 700px;
  width: 90%;
  text-align: center;
}

.float_div{
  max-width: 700px;
  width: 90%;
  height:100%;
  background-color:#494955;
  border-radius: 5px;
  margin-top: 25px;
}

input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }


.mobile-toolbar {
  width: 100%;
}

.mobile-toolbar .p-toolbar {
  flex-wrap: nowrap !important;
}

.mobile-toolbar .p-toolbar .credits-son {
  justify-content: end !important;
}

.mobile-wallet-card {
  display: none !important;
}

.web-wallet-card {
  display: block !important;
}

@media (max-width: 991px){
  .mobile-wallet-card {
    display: block !important;
  }
  
  .web-wallet-card {
    display: none !important;
  }

  .p-toolbar button.register {
    margin-right: 5px !important;
  }
  
  .p-toolbar {
    flex-wrap: nowrap !important;
  }
  
  .p-toolbar [ng-reflect-label="CAJERO"] {
    display: none;
  }
  
  .p-toolbar .p-button-icon.pi.pi-angle-down {
    display: none;
  }
  
  div.toolbar-container {
    justify-content: space-between !important;
  }

  .p-toolbar .p-element.p-button.p-component.p-button-icon-only {
    margin-left: 0 !important;
    width: 55px !important;
    margin-right: 10px !important;
  }
}